import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "Tartare",

  subtitle: "sauce With mayonnaise",

  background: require("../../media/tartare.jpg"),

  details: [
    "Ideal with grilled or baked fish or even fries.",
    `Try it with <a href='#/Baked_salmon_filets'>Baked salmon filets</a>.`,
  ],

  equipment: [
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Mixing_bowl("small, with something to cover/seal"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Mayonnaise("5 Tablespoons"),
    Ingredients.Cornichons(4, "(or one small pickled cucumber) finely diced"),
    Ingredients.Capers("1 Tablespoon", "smaller ones are better"),
    Ingredients.Shallots(1, "finely diced"),
    Ingredients.Parsley("1 handfull of leaves", "finely chopped"),
    Ingredients.Lemon_juice("2 teaspoons", "from 1/2 small lemon"),
  ],

  optional_ingredients: [Ingredients.Greek_yogurt("Replace (some) MAYONNAISE with ")],

  prep: ["Dice all ingredients that need it"],

  prep_time: 4,

  method: ["Mix all ingredients", "Chill in a covered/sealed bowl"],

  cook_time: 1,
};

export default Recipe;
