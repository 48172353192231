import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Shawarma",

  subtitle: "Middle Eastern",

  background: require("../../media/Shawarma.jpg"),

  details: [
    "Cook with ckicken, turkey or lamb.",
    "Marinate for at least 2h for best results.",
    `Serve with <a href='#/Greek_pita'>Greek pita</a> and <a href='#/Yoghurt_sauce'>Yoghurt sauce</a>.`,

    "FILLER INGREDIENTS: Red onion slices, Sliced iceberg lettuce, Tomato slices, Hot sauce etc.",
  ],

  equipment: [
    Equipment.Mixing_bowl("medium, with lid or cling film"),
    Equipment.Pestil_and_mortar("or garlic crusher"),
    Equipment.Frying_pan("large and thick, or griddle"),
    Equipment.Aluminium_foil("to cover the cooked chicken"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Chicken_thighs("5 to 6", "boneless and skinless"),
    Ingredients.Garlic("2 cloves, minced"),
    Ingredients.Coriander("2 teaspoons", "ground"),
    Ingredients.Cumin("2 teaspoons", "ground"),
    Ingredients.Hot_pepper("1 teaspoons", "ground"),
    Ingredients.Paprika("2 teaspoons", "ground"),
    Ingredients.Salt("2 teaspoons"),
    Ingredients.Pepper("1 teaspoon"),
    Ingredients.Lemon_juice("2 Tablespoons", "from 1 lemon"),
    Ingredients.Olive_oil(30, "2 Tablespoons"),
  ],

  optional_ingredients: [
    Ingredients.Cardamom("2 teaspoons", "ground - for more of a middle-eastern flavour"),
    Ingredients.Olive_oil(15, "1 Tablespoon - for frying"),
  ],

  prep: [
    "Squeeze the lemon juice",
    "Trim the hard tip of the cloves",
    "Peel garlic cloves and crush them",
    "Mix all condiments in the bowl",
    "Add chicken thighs and coat well",
    "Cover with a lid and refrigerate for at least 1h, ideally 2h or more",
  ],

  prep_time: 15,

  wait_time: 60,

  method: [
    "Heat the pan or griddle (optional: with 1 Tabelspoon of oil)",
    "Place thighs in the pan and fry for 4-5 minutes on one side",
    "Flip them and fry for 3-4 minutes",
    "Remove chicken from pan and rest under aluminium foil for 5 minutes",
    "Cut into small slices before serving",
  ],

  cook_time: 45,
};

export default Recipe;
