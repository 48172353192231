import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.entree,

  title: "eggs",

  subtitle: "perfectly boiled",

  background: require("../../media/boiled-eggs.jpg"),

  details: ["Fully cooked with a gooey yolk!", "Easy to peel."],

  equipment: [Equipment.Pot("medium to large"), Equipment.Lid(), Equipment.Spoon()],

  optional_equipment: [],

  ingredients: [Ingredients.Egg(4, "for a medium pot, 6 for a larger one - cold from the fridge")],

  optional_ingredients: [],

  prep: ["Fill pot with water enough to confortably cover the eggs", "Bring to boil with lid on"],

  prep_time: 5,

  method: [
    "When water is boiling lower the eggs one at a time gently to the bottom of the pot wsing the spoon",
    "Put lid back on to bring back to boi as quickly as possible",
    "When water is boiling the lid can be moved a bit allowing some steam to escape",
    "Set a timer for exactly 7m 30s",
    "For harder eggs with yolk fully cooked use 7m 45s",
    "For small eggs take away 15s and for large ones add 15s",
    "When the timer is up drain the boiling water from the pot in the sink",
    "Be careful to not let any of the eggs fall out",
    "Start running cold water from the faucet over the eggs in the pot",
    "Run water for 3+ minutes - this will ensure they peel easily",
  ],

  cook_time: 10,
};

export default Recipe;
