import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Potatoes",

  subtitle: "with onions",

  background: require("../../media/potatoes_with_onions.jpg"),

  details: [
    "Hearty side full of flavour!",
    "Add bacon or chorizo and make it a main.",
    `Try it with <a href='#/Tomato_chicken'>Tomato chicken</a>.`,
  ],

  equipment: [
    Equipment.Pot("medium"),
    Equipment.Colander(),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Wok("large - or a big frying pan"),
    Equipment.Spatula(),
  ],

  optional_equipment: [Equipment.Fork()],

  ingredients: [
    Ingredients.Potatoes("600g"),
    Ingredients.Olive_oil(15, "1 Tablespoon"),
    Ingredients.Shallots(4, "or equivalent white or red onion - finely sliced"),
    Ingredients.Garlic("4 cloves", "minced"),
    Ingredients.Paprika("2 teaspoons"),
    Ingredients.Caraway_seeds("1 teaspoon"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Bacon("200 g", "cubes, cooked")],

  prep: [
    "Cut potatoes in 2cm cubes",
    "Boil water with a bit of salt",
    "Boil potatoes until fork easily goes in",
    "Drain potatoes in the colander and let them cool",
    "(optional) Cook the bacon cubes or chorizo slices and set aside",
  ],

  prep_time: 25,

  method: [
    "Cut shallots in fine strips",
    "Mince the garlic",
    "Heat the oil in the wok on medium heat",
    "Cook the shallots until soft",
    "Add the paprika and caraway seeds and mix well",
    "Add in the potatoes",
    "(optional) Add in the bacon / chorizo",
    "Add in the garlic",
    "Grind salt and pepper",
    "Toss and mix until potatoes are hot - 3-4 minutes",
  ],

  cook_time: 15,
};

export default Recipe;
