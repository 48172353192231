export const is = (recipe, prop) => {
  return ![...recipe.ingredients].some((item) => item[prop] !== true);
};

export const isVegetarian = (recipe) => is(recipe, "vegetarian");

export const isVegan = (recipe) => is(recipe, "vegan");

export const isKeto = (recipe) => is(recipe, "keto");

export const time = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const minutes_remainder = minutes - 60 * hours;

  return minutes > 60 ? `${hours}h ${minutes_remainder ? `${minutes - 60 * hours}m` : ""}` : `${minutes}m`;
};
