import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Corn on the cob",

  subtitle: "oven-baked",

  background: require("../../media/corn.jpg"),

  details: ["Easy to make and full of taste!"],

  equipment: [
    Equipment.Aluminium_foil(),
    Equipment.Baking_tray(),
    Equipment.Knife("for butter"),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [],

  ingredients: [Ingredients.Corn(), Ingredients.Salt(), Ingredients.Butter(5, "a thin slice, more if prefered ")],

  optional_ingredients: [],

  prep: [
    "Remove all the outside layers around the corn",
    "Cut a square of aluminium foil",
    "Place a thin slice of butter in the center",
    "Place the corn on top of the butter vertically as you work",
    "Grind salt generously",
    "Close the foil sides and twist-close the edges",
    "Twist the top and bottom end like wrapped candy",
  ],

  prep_time: 5,

  method: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Bake on a baking tray for 40-50 minutes based on the size of the corn",
    "(optional), butter and salt the corn when opened",
  ],

  cook_time: 45,
};

export default Recipe;
