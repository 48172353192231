import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.entree,

  title: "eggs",

  subtitle: "sunny-side-up",

  background: require("../../media/eggs.jpg"),

  details: [
    "Perfectly cooked sunny-side-up eggs!",
    "Uses no oil whatsoever!",
    "Cook 3-4 eggs depending on the size of the pan.",
    "It's important the lid covers the pan and seals the butter in the pan to cook the eggs on all sides.",
  ],

  equipment: [
    Equipment.Frying_pan("small to medium"),
    Equipment.Glass_lid({
      details: "the lid must be transparent",
      important: true,
    }),
    Equipment.Spatula(),
    Equipment.Plate("large"),
  ],

  optional_equipment: [],

  ingredients: [Ingredients.Egg(4, "3 for small pans"), Ingredients.Butter(5)],

  optional_ingredients: [Ingredients.Salt(), Ingredients.Pepper()],

  prep: [
    "Butter the whole interior of the pan all the way to the edge",
    "Crack the eggs in the pan carfefully to not break the yolk",
    "Cover with a glass lid - this is importnat to see the cooking progress of the eggs",
  ],

  prep_time: 5,

  method: [
    "Move the pan with eggs to low-medium heat and follow the cooking process",
    "Keep the lid on at all times during cooking",
    "When the white part of the egg above the yolks begins cooking remove the lid",
    "Remove the pan away from heat",
    "Using the spatula and rotating the pan while keeping it flat detach the eggs from the pan",
    "Slide eggs on a cold plate to stop cooking and apply salt and pepper if preferred",
    "Use spatula to separate eggs if needed",
  ],

  cook_time: 10,
};

export default Recipe;
