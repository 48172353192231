import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { is, time } from "./utils.js";
import { recipes } from "./db/recipes";

function App() {
  const { id } = useParams();
  // console.log("id", id);

  const currentRecipe = recipes[id];

  const [equipmentChecked, setEquipmentChecked] = useState([]);
  const [ingredientsChecked, setIngredientsChecked] = useState([]);
  const [prepChecked, setPrepChecked] = useState([]);
  const [methodChecked, setMethodChecked] = useState([]);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [social, setSocial] = useState({ title: "", image: "", imageWidth: 0, imageHeight: 0 });

  useEffect(() => {
    setEquipmentChecked([
      ...currentRecipe.equipment.map((item) => false),
      ...currentRecipe.optional_equipment.map((item) => false),
    ]);
    setIngredientsChecked([
      ...currentRecipe.ingredients.map((item) => false),
      ...currentRecipe.optional_ingredients.map((item) => false),
    ]);
    setPrepChecked(currentRecipe.prep.map((item) => false));
    setMethodChecked(currentRecipe.method.map((item) => false));
    setBackgroundStyle({
      backgroundImage: `url(${currentRecipe.background})`,
    });

    const img = new Image();
    img.src = currentRecipe.background;

    img.onload = () => {
      setSocial({
        title: `${currentRecipe.title.toUpperCase()} • 🍲 • Radu's Recipes`,
        image: `${window.location.origin}${currentRecipe.background}`,
        imageWidth: img.width,
        imageHeight: img.height,
      });
    };

    window.scrollTo(0, 0);
  }, [currentRecipe]);

  function handleCheck(list, n) {
    switch (list) {
      case "equipment":
        equipmentChecked && setEquipmentChecked(equipmentChecked.map((c, i) => (i === n ? !c : c)));
        break;

      case "ingredients":
        ingredientsChecked && setIngredientsChecked(ingredientsChecked.map((c, i) => (i === n ? !c : c)));
        break;

      case "prep":
        prepChecked && setPrepChecked(prepChecked.map((c, i) => (i === n ? !c : c)));
        break;

      case "method":
        methodChecked && setMethodChecked(methodChecked.map((c, i) => (i === n ? !c : c)));
        break;

      default:
        break;
    }
  }

  function allChecked(list) {
    switch (list) {
      case "equipment":
        return equipmentChecked.find((c) => c === false) === undefined;

      case "ingredients":
        return ingredientsChecked.find((c) => c === false) === undefined;

      case "prep":
        return prepChecked.find((c) => c === false) === undefined;

      case "method":
        return methodChecked.find((c) => c === false) === undefined;

      default:
        return false;
    }
  }

  //   const completed = (list) => {
  //     return !allChecked(list);
  //   };

  function completedClass(list) {
    return allChecked(list) ? "completed" : undefined;
  }

  return (
    <>
      <Helmet>
        <title>{social.title}</title>
        <meta name="description" content={currentRecipe.subtitle} />

        <meta content={social.title} property="og:image:alt" />
        <meta content={social.title} name="twitter:image:alt" />

        <meta content={currentRecipe.title} property="og:title" />
        <meta content={currentRecipe.title} name="twitter:title" />
        <meta content={currentRecipe.title} property="article:section" />

        <meta content={currentRecipe.subtitle} name="description" />
        <meta content={currentRecipe.subtitle} property="og:description" />
        <meta content={currentRecipe.subtitle} name="twitter:description" />

        <meta content={social.image} property="og:image" />
        <meta content={social.image} name="twitter:image" />
        <meta content={social.imageWidth} property="og:image:width" />
        <meta content={social.imageHeight} property="og:image:height" />
      </Helmet>

      <main style={backgroundStyle}>
        <button className="back">
          <Link to={"/"}>📖</Link>
        </button>
        <article>
          <section className="recipe-title" style={backgroundStyle}>
            <h1>
              <span>{currentRecipe.title.toUpperCase()}</span>
              {currentRecipe.subtitle && <span className="subtitle">{currentRecipe.subtitle.toUpperCase()}</span>}
            </h1>

            <div className="badges">
              {is(currentRecipe, "vegetarian") && <span className="recipe-badge vegetarian">🥗</span>}
              {is(currentRecipe, "vegan") && <span className="recipe-badge vegan">🥦</span>}
              {is(currentRecipe, "keto") && <span className="recipe-badge keto">🍖</span>}
            </div>
          </section>

          <ul className="recipe-details">
            <li>
              <span>⏰ PREP: {time(currentRecipe.prep_time)} </span>
              {currentRecipe.wait_time && <span>⏳ WAIT: {time(currentRecipe.wait_time)} </span>}
              <span>⏲ COOK: {time(currentRecipe.cook_time)}</span>
            </li>
            {currentRecipe.details.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} className="with-links"></li>
            ))}
          </ul>

          <p className="here-it-is">Here's the recipe:</p>
          <section className="steps">
            <details
              open
              // open={completed("ingredients")}
              className={completedClass("ingredients")}
            >
              <summary>
                <h2>
                  <i>🛒</i>Ingredients
                </h2>
              </summary>

              <ul>
                {currentRecipe.ingredients.map((item, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={ingredientsChecked[index] || false}
                        onChange={(e) => handleCheck("ingredients", index)}
                      />
                      <span>
                        {item.quantity && (
                          <>
                            <span>{item.quantity}</span>
                            <span>{item.unit} </span>
                          </>
                        )}
                        <strong> {item.name.toUpperCase()} </strong>
                        {item.details && <span className={item.important && "important"}>{` - ${item.details}`}</span>}
                      </span>
                    </label>
                  </li>
                ))}

                {currentRecipe.optional_ingredients
                  ? currentRecipe.optional_ingredients.map((item, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            checked={ingredientsChecked[index + currentRecipe.ingredients.length] || false}
                            onChange={(e) => handleCheck("ingredients", index + currentRecipe.ingredients.length)}
                          />
                          <span>
                            {item.quantity && (
                              <>
                                <span>{item.quantity}</span>
                                <span>{item.unit} </span>
                              </>
                            )}
                            <strong> {item.name.toUpperCase()} </strong>
                            <span className="optional-ingredient"> (optional) </span>
                            {item.details && (
                              <span className={item.important && "important"}>{` - ${item.details}`}</span>
                            )}
                          </span>
                        </label>
                      </li>
                    ))
                  : null}
              </ul>
            </details>

            <details
              open
              // open={completed("equipment")}
              className={completedClass("equipment")}
            >
              <summary>
                <h2>
                  <i>🥣</i>Equipment
                </h2>
              </summary>

              <ul>
                {currentRecipe.equipment.map((item, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={equipmentChecked[index] || false}
                        onChange={(e) => handleCheck("equipment", index)}
                      />
                      <span>
                        {item.length ? (
                          <>
                            <strong>{item[0].name}</strong> - {item[1]}
                          </>
                        ) : (
                          <>
                            <strong>{item.name.toUpperCase()}</strong>
                            {item.details && (
                              <>
                                <span> - </span>
                                <span className={item.important && "important"}>{item.details}</span>
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </label>
                  </li>
                ))}

                {currentRecipe.optional_equipment
                  ? currentRecipe.optional_equipment.map((item, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            checked={equipmentChecked[index + currentRecipe.equipment.length] || false}
                            onChange={(e) => handleCheck("equipment", index + currentRecipe.equipment.length)}
                          />
                          <span>
                            {item.quantity && (
                              <>
                                <strong>{item.quantity}</strong>
                                <span>{item.unit} </span>
                              </>
                            )}
                            <strong> {item.name.toUpperCase()} </strong>
                            <span className="optional-ingredient"> (optional) </span>
                            {item.details && (
                              <span className={item.important && "important"}>{` - ${item.details}`}</span>
                            )}
                          </span>
                        </label>
                      </li>
                    ))
                  : null}
              </ul>
            </details>

            <details
              open
              // open={completed("prep")}
              className={completedClass("prep")}
            >
              <summary>
                <h2>
                  <i>🔪</i>Prep
                </h2>
              </summary>
              <ul>
                {currentRecipe.prep.map((line, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={prepChecked[index] || false}
                        onChange={(e) => handleCheck("prep", index)}
                      />
                      <span dangerouslySetInnerHTML={{ __html: line }} className="with-links"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </details>

            <details
              open
              // open={completed("method")}
              className={completedClass("method")}
            >
              <summary>
                <h2>
                  <i>🥘</i>Method
                </h2>
              </summary>

              <ul>
                {currentRecipe.method.map((line, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={methodChecked[index] || false}
                        onChange={(e) => handleCheck("method", index)}
                      />
                      <span dangerouslySetInnerHTML={{ __html: line }} className="with-links"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </details>
          </section>
        </article>
      </main>
    </>
  );
}

export default App;
