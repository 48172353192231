import React from "react";
import { Link } from "react-router-dom";
import { recipes } from "../db/recipes";

function RecipeCards({ recipeList, type }) {
  const filteredRecipes = recipeList.filter((r) => recipes[r].type === type);

  return (
    <ol className="recipe-cards">
      {filteredRecipes.map((name, index) => (
        <li key={index} className="menu-link" style={{ backgroundImage: `url(${recipes[name].background})` }}>
          <Link to={name}>
            <p>
              <span className="title">{recipes[name].title.toUpperCase()}</span>
              <span className="subtitle">{recipes[name].subtitle.toUpperCase()}</span>
            </p>
          </Link>
        </li>
      ))}
    </ol>
  );
}

export default RecipeCards;
