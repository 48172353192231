import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "pico de gallo",

  subtitle: "side salad",

  background: require("../../media/pico_de_gallo.jpg"),

  details: [
    "Fresh tasty side salad",
    "Perfect for mezican dishes",
    `Try it with 
    <a href='#/Fajitas'>Fajitas</a>
    and 
    <a href='#/Guacamole'>Guacamole</a>.`,
  ],

  equipment: [Equipment.Cutting_board(), Equipment.Knife(), Equipment.Ceramic_bowl("With any cover")],

  optional_equipment: [Equipment.Sieve("for lime juice")],

  ingredients: [
    Ingredients.Tomatoes("240g", "diced"),
    Ingredients.Onion("100g", "white, red or green, finely diced"),
    Ingredients.Coriander("1 bunch", "leafs without branches, finely diced"),
    Ingredients.Lime_juice("", "from 1 lime"),
    Ingredients.Salt("1/2 teaspoon"),
  ],

  optional_ingredients: [Ingredients.Hot_pepper("As preffered", "fresh, finely diced")],

  prep: [
    "Dice the tomatoes, oninon, coriander and if preffered hot pepper",
    "Squeeze the juice from the lime without seeds, some pulp is fine",
  ],

  prep_time: 8,

  method: [
    "Mix all ingredients in the bowl",
    "Rest for 15 minuts to let the flavours meld",
    "Keep bowl covered in the fridge",
  ],

  cook_time: 2,
};

export default Recipe;
