import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "garlic sauce",

  subtitle: "With Créme Fraîche",

  background: require("../../media/garlic.jpg"),

  details: ["Fresh and full on taste!"],

  equipment: [Equipment.Pestil_and_mortar("large"), Equipment.Knife(), Equipment.Cutting_board()],

  optional_equipment: [],

  ingredients: [
    Ingredients.Garlic("5 cloves", "more or fewer if prefered"),
    Ingredients.Creme_fraiche("300g"),
    Ingredients.Olive_oil(15, "1 Tablespoon"),
    Ingredients.Salt("1/2 teaspoon"),
  ],

  optional_ingredients: [Ingredients.Sparkling_mineral_warter(false, "to dilute the sauce")],

  prep: ["Trim the hard tip of the cloves", "Peel garlic cloves"],

  prep_time: 2,

  method: [
    "Put cloves in the mortar with salt and a teaspoon of olive oil",
    "Grind all to a pulp",
    `Mix in the rest of the olive oil and the ${Ingredients.Creme_fraiche().name}`,
    "(optional) Dilute the sauce with sparkling mineral water",
  ],

  cook_time: 3,
};

export default Recipe;
