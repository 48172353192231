import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Lemon chicken",

  subtitle: "Greek marinade",

  background: require("../../media/greek-lemon-chicken.jpg"),

  details: [
    "The lemon marinade makes the ckicen thighs very tender and soft",
    "Marinate for at least 2h for best results.",
  ],

  equipment: [
    Equipment.Baking_dish("deep, medium size"),
    Equipment.Mixing_bowl("medium, with lid"),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Chicken_thighs("6 to 8", "boneless and skinless"),
    Ingredients.Olive_oil(80),
    Ingredients.Lemon_juice("1/4 cup, from one large lemon"),
    Ingredients.Garlic("4 large cloves, minced"),
    Ingredients.Oregano("1 teaspoon"),
    Ingredients.Thyme("1 teaspoon"),
    Ingredients.Mustard("2 teaspoons, Dijon"),
    Ingredients.Salt("2 teaspoons"),
    Ingredients.Pepper("1 teaspoon"),
  ],

  optional_ingredients: [],

  prep: [
    "Mix all condiments in a bowl",
    "Add chicken thighs and coat well",
    "Cover with a lid and refrigerate for at least 1h, ideally 2h or more",
  ],

  prep_time: 15,

  wait_time: 60,

  method: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Place thighs in the baking dish, skin side up",
    "Pour the rest of the marinate on top",
    "Bake for 45min",
  ],

  cook_time: 45,
};

export default Recipe;
