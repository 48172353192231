import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "Horseradish",

  subtitle: "sauce With mayonnaise",

  background: require("../../media/horseradish_sauce.jpg"),

  details: ["Great with cold cuts!", "All ingredient quantities can vary to taste, add more of each if preffered."],

  equipment: [Equipment.Mixing_bowl("small, with something to cover/seal"), Equipment.Grate("fine")],

  optional_equipment: [],

  ingredients: [
    Ingredients.Mayonnaise("120g"),
    Ingredients.Horseradish("2 Tablespoons (before gating)", "fresh root"),
    Ingredients.Lemon_juice("1 teaspoon", "from 1/4 lemon"),
    Ingredients.Pepper("1/2 teaspoon"),
  ],

  optional_ingredients: [
    Ingredients.Salt("1/2 teaspoon"),
    Ingredients.Worcestershire_sauce("1 teaspoon"),
    Ingredients.Chives("1 teaspoon", "Finely diced"),
    Ingredients.Creme_fraiche("Replace (some) MAYONNAISE with "),
  ],

  prep: ["Grate fresh horseradish finely and (optionally) chop chives"],

  prep_time: 4,

  method: ["Mix all ingredients", "Chill in a covered/sealed bowl"],

  cook_time: 1,
};

export default Recipe;
