import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.bread,

  title: "Carrot cake",

  subtitle: "Cupcakes",

  background: require("../../media/carrot_cupcakes.jpg"),

  details: ["Moist and delicious!", "This recipe will make 6 cupcakes."],

  equipment: [
    Equipment.Mixer("handheld", "with wisk attachments"),
    Equipment.Mixing_bowl("medium - for the mix"),
    Equipment.Mixing_bowl("medium - for the frosting"),
    Equipment.Whisk(),
    Equipment.Spatula("soft, to mix"),
    Equipment.Sieve("for the powdered sugar"),
    Equipment.Cupcake_papers(6),
    Equipment.Cupcake_tray("for 6 or 12"),
    Equipment.Cling_film("to cover the frosting bowl"),

    Equipment.Oven_gloves(),
    Equipment.Baking_rack(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Bread_flour(65, "1/2 cup"),
    Ingredients.Baking_soda("1/2 teasposons"),
    Ingredients.Salt("1/8 teaspoons", "a small pinch"),
    Ingredients.Cinamon("1/2 teaspoons"),
    Ingredients.Vegetable_oil(75),
    Ingredients.Sugar("50g WHITE"),
    Ingredients.Sugar("50g BROWN", "or 35g WHITE SUGAR and 1 Tablespoon Honey/Golden Syrup"),
    Ingredients.Vanila("1/4 teaspoons"),
    Ingredients.Egg(1, "at room temperature"),
    Ingredients.Carrots("75g", "peeled and grated"),
    Ingredients.Nuts("30g", "PECANS and/or WALNUTS - crushed"),
    Ingredients.Raisins("20g"),

    Ingredients.Soft_cheese("60g", "at room temperature"),
    Ingredients.Powdered_sugar("35g"),
    Ingredients.Heavy_cream("20g", "cold"),
    Ingredients.Nuts("12g", "PECANS and/or WALNUTS coarsley chopped", "for decoration"),
  ],

  optional_ingredients: [],

  prep: [
    "Adjust the oven rack to the lower third position",
    "Preheat the oven at 200°C / 180°C FAN",
    "Place the cupcake papers in the tray",
    "Peel and grate the carrot",
    "Crush the nuts for the mix",
    "Chop the nuts for decoration",
  ],

  prep_time: 10,

  method: [
    "CUPCAKES: Whisk flour, baking soda, salt, and cinnamon in a medium bowl until very well blended. Set aside.",
    "In a separate bowl, whisk the oil, granulated sugar, brown sugar, and vanilla.",
    "Add in the egg (for more eggs add one at a time, whisking after each one).",
    "Switch to the soft spatula.",
    "Scrape the sides and bottom of the bowl, then add the dry ingredients in three parts, gently stirring until they disappear and the batter is smooth.",
    "Stir in the carrots, nuts, and raisins.",
    "Divide the cake batter between the cupcakes - should be aat the same level at the tray",
    "Bake for 25 minutes",

    "FROSTING: In a medium bowl, beat the cream cheese with a handheld mixer on medium speed until smooth and creamy, about 1 minute.",
    "Sieve the powdered sugar over the mix. Mix well",
    "Pour in the heavy cream",
    "Beat on medium speed for 2 to 3 minutes until the frosting is whipped and creamy",
    "Chill the bowl covered with cling film until ready to frost the cake",

    "COOLING: Cool the tray on the rack for 15 minutes",
    "Remove the cupcakes from the tray, paper still on",
    "Cool the cupcakes on the rack for 1h",
    "Once cupcakes are cool they can be decorated with frosting and nuts on top",
  ],

  cook_time: 40,

  wait_time: 90,
};

export default Recipe;
