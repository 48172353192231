import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Brussel sprouts",

  subtitle: "baked in the oven",

  background: require("../../media/brussel_sprouts.jpg"),

  details: ["Perfect for any dinner as a tasty side!"],

  equipment: [
    Equipment.Colander("to wash the sprouts"),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Baking_tray("for all the sprouts cut in half"),
    Equipment.Baking_paper("to cover the tray"),
    Equipment.Ceramic_bowl("for serving"),
    Equipment.Lid("To keep bowl warm"),
  ],

  optional_equipment: [Equipment.Cheese_grate("for Parmesan")],

  ingredients: [
    Ingredients.Brussel_sprouts(400, "1 bag"),
    Ingredients.Olive_oil(15, "to drizzle"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Parmesan(20)],

  prep: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Wash the sprouts in the colander and dry",
    "Do not remove sprout leafs or bottom stem",
    "Line baking tray with baking paper",
  ],

  prep_time: 10,

  method: [
    "Cut all the sprouts in half lengthwise",
    "Place in the tray on the paper, with the cut side down",
    "Drizzle a bit of olive oil on all sprouts",
    "Grind salt and pepper over all sprouts",
    "Bake in the oven for 35 minutes",
    "Outer leafs should begin to scorch, and bottom sides to brown",
    "(optional) Grate parmesan over the sprouts before moving to bowl",
    "Use the baking paper to pour all the sprouts into a bowl",
    "Cover wtih a lid to keep warm",
  ],

  cook_time: 40,
};

export default Recipe;
