import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Green beans",

  subtitle: "With pancetta",

  background: require("../../media/pancetta_green_beans.jpg"),

  details: [
    "Very tasty side or add more pancetta as a main.",
    "Pancetta can be mixed with or substituted by lardons or Chorizo.",
    `Try it with <a href='#/Tomato_chicken'>Tomato chicken</a>.`,
  ],

  equipment: [
    Equipment.Colander("to wash and drain the beans"),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Pot("large"),
    Equipment.Wok("large"),
    Equipment.Frying_pan("small"),
    Equipment.Spatula(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Green_beans(400, "2 bags"),
    Ingredients.Pancetta("200g", "diced"),
    Ingredients.Sesame_seeds("2 tablespoons", "toasted"),
    Ingredients.Olive_oil("", "to brush the pan before toasting the sesame seeds"),
    Ingredients.Soy_sauce(15, "1 Tablespoon, reduced salt"),
    Ingredients.Garlic("6 cloves", "minced"),
    Ingredients.Onion("6 spring", "finely chopped"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Chilli_flakes("1 teaspoon")],

  prep: [
    "Wash the beans in the colander",
    "Cut the ends of all the beans",
    "Start the pot with 3l of water and salt to boil",
    "Lightly brush a small frying pan with olive oil",
    "Toast the sesame seeds until they brown up a bit",
    "When the pot boils add in the beans and boil for 3 minutes",
    "Drain in the colander",
    "Dice the pancetta into 5mm cubes",
    "Chop the spring onions finely",
    "Mince the garlic",
  ],

  prep_time: 15,

  method: [
    "In a big wok start fryng the pancetta, tossing regularly until the corners brown up a bit",
    "After 10 minutes add the onions, cook for 2 minutes",
    "Add in the beans and cook for 3 minutes",
    "Add in the garlic, sesame seeds and grind black pepper on top",
    "(optional) Add in the chilli flakes",
    "Keep mixing and cook for 1 minute",
    "Add the soy sauce and mix well for 1 minute while still cooking",
    "Remove from heat",
  ],

  cook_time: 20,
};

export default Recipe;
