import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.bread,

  title: "Banana bread",

  subtitle: "A delicious bake!",

  background: require("../../media/banana_bread.jpg"),

  details: ["Made with ripe or over-ripe bananas."],

  equipment: [
    Equipment.Mixer("handheld", "with wisk attachments"),
    Equipment.Mixing_bowl("medium - for the dry ingredients"),
    Equipment.Mixing_bowl("medium - for the bananas"),
    Equipment.Mixing_bowl("large - for the mix"),
    Equipment.Spatula("soft, to mix"),
    Equipment.Whisk(),
    Equipment.Masher(),
    Equipment.Loaf_tin("small, for big loaf tins double the quantities"),
    Equipment.Aluminium_foil("to cover the tin"),
    Equipment.Oven_gloves(),
    Equipment.Baking_rack(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Bread_flour(250),
    Ingredients.Baking_soda("1/2 teasposons"),
    Ingredients.Salt("1/8 teaspoons", "a small pinch"),
    Ingredients.Cinamon("1/4 teaspoons"),
    Ingredients.Butter(60, "soft, at roomm temperature - plus a bit more for greasing the loaf tin"),
    Ingredients.Sugar("75g BROWN", "or 60g WHITE SUGAR and 1 Tablespoon Honey/Golden Syrup"),
    Ingredients.Egg(1, "at room temperature"),
    Ingredients.Yogurt("80g PLAIN ", "or SOUR CREAM"),
    Ingredients.Banana("230g", "3 small banans - mashed"),
    Ingredients.Vanila("1/2 teaspoons"),
  ],

  optional_ingredients: [Ingredients.Nuts("25g", "WALNUTS, CASHEWS or PECANS - crushed")],

  prep: [
    "Adjust the oven rack to the lower third position",
    "Preheat the oven at 200°C / 180°C FAN",
    "Grease a metal loaf tin with butter. Set aside.",
    "Mash the bananas in a medium bowl. Set aside.",
  ],

  prep_time: 15,

  method: [
    "Whisk the flour, baking soda, salt, and cinnamon together in a medium bowl. Set aside.",
    "Use the large bowl and the electric mixer with wisk attachments on HIGH",
    "Beat the butter and brown sugar together until smooth and creamy, about 2 minutes",
    "Add in the egg, beat well",
    "Add in the yogurt, beat well",
    "Add in the mashed bananas, beat well",
    "Add in the vanilla extract, beat well",
    "Switch the mixer to SLOW",
    "Slowly beat the dry ingredients (adding gradually) into the wet ingredients until no flour pockets remain. Do not over-mix",
    "(optional) Fold in the nuts using the spatula",
    "Pour and spread the batter into the prepared loaf tin",
    "Bake for 30 minutes",
    "Loosely cover the tin with aluminum foil",
    "Bake for another 35 minutes",
    "Remove the tin from the oven and let it cool on the rack for 1h",
    "Remove the bread from the tin and let it cool more on the rack for 1h before cutting",
  ],

  cook_time: 80,

  wait_time: 120,
};

export default Recipe;
