import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "pizza sauce",

  subtitle: "with passata",

  background: require("../../media/pizza-sauce.jpg"),

  details: ["Just a few ingredients - keep it in the frige!", "Ideal for <a href='#/Pizza'>Pizza</a>"],

  equipment: [Equipment.Tupperware("medium"), Equipment.Spoon()],

  optional_equipment: [],

  ingredients: [
    Ingredients.Tomato_sauce("450g", "passata"),
    Ingredients.Tomato_paste("1 Tablespoon"),
    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Oregano("1 teaspoon"),
    Ingredients.Salt("1 teaspoon"),
    Ingredients.Sugar("1/2 teaspoon"),
  ],

  optional_ingredients: [],

  prep: ["Make sure the tupperware is very clean as it will hold the sauce in the fridge for a long time"],

  prep_time: 2,

  method: ["Mix all ingredients well in the container", "Keep refrigerated"],

  cook_time: 3,
};

export default Recipe;
