import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Tomato chicken",

  subtitle: "Marinated, with basil",

  background: require("../../media/tomato_marinade_chicken.jpg"),

  details: [
    "Quick and easy to make!",
    "The marinade keeps the chicken suculent.",
    `Try it with <a href='#/Pancetta_green_beans'>Pancetta green beans</a> or <a href='#/Potatoes_with_onions'>Potatoes with onions</a>.`,
  ],

  equipment: [Equipment.Mixing_bowl(), Equipment.Baking_tray(), Equipment.Spoon()],

  optional_equipment: [],

  ingredients: [
    Ingredients.Chicken_breast("300g", "two pieces, or turkey"),

    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Tomato_paste("2 Tablespoons"),
    Ingredients.Vinegar(15, "1 Tablespoon"),
    Ingredients.Basil("1 teaspoon", "dry"),
    Ingredients.Salt("1/2 teaspoon"),
    Ingredients.Pepper("1/4 teaspoon"),
  ],

  optional_ingredients: [Ingredients.Chilli_powder("1/2 teaspoon")],

  prep: [
    "In a bowl mix all of the marinade ingredients together and set aside",
    "Put the chicken breasts in a pan and pour over the marinade",
    "Leave for 30 minutes for the flavours to infuse",
  ],

  prep_time: 5,

  wait_time: 30,

  method: ["Preheat the oven at 220°C / 200°C FAN", "Bake for 25 minutes"],

  cook_time: 25,
};

export default Recipe;
