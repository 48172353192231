import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Cabbage stew",

  subtitle: "with fresh cabbage",

  background: require("../../media/varza_calita.jpg"),

  details: ["Tasty side for grilled sausages or pork.", "Pickled cabbage or Sauerkraut are too salty for this."],

  equipment: [
    Equipment.Mixing_bowl("large - for the cabbage"),
    Equipment.Wok("very large, with LID"),
    Equipment.Spatula("Wooden"),
    Equipment.Cutting_board(),
    Equipment.Knife(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Cabbage(750, "1 small WHITE cabbage"),
    Ingredients.Bell_pepper("1/2", "red"),
    Ingredients.Onion("1/2", "white"),
    Ingredients.Tomatoes("1/2", "medium, ripe"),
    Ingredients.Tomato_paste("1 Tablespoon"),
    Ingredients.Olive_oil(15, "1 Tablespoon"),
    Ingredients.Paprika("1 teaspoon"),
    Ingredients.Bay_leafs(2, "whole"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [
    Ingredients.Pancetta("50-70g LARDONS (smoked) or"),
    Ingredients.Thyme("2-3 branches of fresh"),
  ],

  prep: [
    "Cut the cabbage in quarters",
    "Slice the cabbage into fine strips",
    "Move to the bowl and rub with salt for a minute",
    "Dice the bell pepper finely",
    "Dice the onion finely",
    "Dice the tomato finely",
  ],

  prep_time: 20,

  method: [
    "Warm up the oil in the wok on medium flame",
    "Sautee the onion and pepper until soft (3-5 minutes)",
    "Add in the diced tomato, mix and cook for 2-3 minutes",
    "Add in the tomato paste and paprika, mix and cook for 2-3 minutes",
    "Add in the cabagge, mix well then cover with the lid",
    "Add in the bay leafs (optional: thyme and ladrons)",
    "Turn the stove down to a small flame",
    "Check after 10 minutes, mix again and if the bottom of the wok is dry add water (3-5 Tablespoons)",
    "After another 10 minutes, mix again and if the bottom of the wok is dry add more water",
    "After 10 minutes the cabbage should be cooked",
    "Remove from heat",
    "Grind salt and pepper and mix well",
    "Leave to rest without the lid for 10 minutes",
    "Remove bay leafs and thyme branches before serving",
  ],

  cook_time: 40,
};

export default Recipe;
