import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";

import Menu from "./Menu";
import App from "./App";
import "./App.scss";

const router = createHashRouter([
  {
    path: "/",
    element: <Menu />,
  },
  {
    path: "/:id",
    element: <App />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Helmet>
      <title>Radu's Recipes</title>
      <meta name="description" content="All my favourite recipes" />
    </Helmet>
    <RouterProvider router={router} />
  </React.StrictMode>
);
