import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Leg of Lamb",

  subtitle: "Slow Roast with Gravy",

  background: require("../../media/leg_of_lamb.jpg"),

  details: [
    "Amazing dinner food with home made gravy!",
    "Usually gravy is hard to make from scratch but with the roast done it's very easy.",
    "* Optional equipment and ingredients are only for the gravy.",
  ],

  equipment: [
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Baking_tray("large, deep"),
    Equipment.Aluminium_foil("enough to cover tray and leg"),
    Equipment.Platter("large, for serving"),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [
    Equipment.Pot("medium, stainless if using a metal whisk"),
    Equipment.Strainer(),
    Equipment.Whisk(),
  ],

  ingredients: [
    Ingredients.Leg_of_lamb("2kg"),
    Ingredients.Rosemary("2 sprigs"),
    Ingredients.Onion(1, "red or white, large"),
    Ingredients.Garlic("1 whole head"),
    Ingredients.Beef_stock(2, "disolved in 750ml hot water"),
    Ingredients.Water(500),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Flour("4 Tablespoons")],

  prep: [
    "Preheat the oven at 170°C / 150°C FAN",
    "Cut onion in 8 wedges",
    "Cut garlic head in half, cutting through all the cloves",
    "Place the oninons, garlic and rosemary in the center of the baking tray",
    "Pepper and salt the outher thigh of the leg of lamb generously and massage in",
    "Place leg of lamb with inner thigh facing up on the bed of vegetables",
    "Pepper and salt the inner thigh of the leg of lamb generously and massage in",
    "Drizzle leg with oilve oil",
    "Pour beef stock and water in the tray, aroud the leg",
    "Cover with aluminium foil making sure it wraps a bit around the edges/handles of the tray",
  ],

  prep_time: 10,

  method: [
    "Move tray gently to the oven as it conains hot liquids",
    "Bake for 4.5h, add 30m for every extra 500g of lamb over 2kg",
    "Take out tray gently and remove aluminium foil",
    "Don't throw away the foil, will be used to keep lamb warm",
    "Flip the leg and put trayback in the oven for another 1h to brown to outher thigh",
    "Remove from the oven, move leg to platter and wrap in the aluminium foil",

    "GRAVY: pour tray contents in the strainer over the pot",
    "Press with wisk to get all the liquids",
    "Simmer the pot on low heat",
    "Wisk continously and pour flour gradually to avoid lumps",
    "Add more salt and pepper, to taste",
    "Wisk every minute until it feels like paint or tomato soup",
  ],

  cook_time: 330,
};

export default Recipe;
