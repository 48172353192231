import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Lamb Loaves",

  subtitle: "Greek recipe, with Feta",

  background: require("../../media/greek-lamb-loaves.jpg"),

  details: [
    "A true Greek dish full of flavour.",
    `Best accompanied by
    <a href='#/Greek_salad'>Greek salad</a>.`,
    "Serve with GREEK YOGURT.",
  ],

  equipment: [
    Equipment.Mixing_bowl(),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Spatula(),
    Equipment.Baking_tray("4 mini loaf tins or larger ramekins"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Lamb_minced("500g"),
    Ingredients.Onion("1/2", "finely chopped"),
    Ingredients.Garlic("2 cloves", "minced"),
    Ingredients.Lemon_zest("1 Tablespoon"),
    Ingredients.Tomato_paste("2 Tablespoons"),
    Ingredients.Egg(1),
    Ingredients.Rosemary("1 Tablespoon", "chopped"),
    Ingredients.Sundried_tomatoes("1/4 cup", "chopped"),
    Ingredients.Feta("100g", "crumbled"),
  ],

  optional_ingredients: [
    Ingredients.Rosemary("for decoration, 1 small stalk for each tin", "to garnish"),
    Ingredients.Greek_yogurt("Serve with"),
  ],

  prep: [
    "Grease the base of the mini loaf tins",
    "In a bowl mix all the non-optional ingredients",
    "Decorate each on top with a rose mary salk",
  ],

  prep_time: 15,

  method: ["Preheat the oven at 200°C / 180°C FAN", "Bake for 25 minutes"],

  cook_time: 25,
};

export default Recipe;
