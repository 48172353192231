import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.bread,

  title: "Greek pita",

  subtitle: "pan-fried",

  background: require("../../media/greek_pita.jpg"),

  details: [
    "This reciepe is for ONE PITA only.",
    "Quick and easy, without any kneading or raising time required!",
    "Fast to cook in a pan, no oven needed.",
  ],

  equipment: [
    Equipment.Mixing_bowl(),
    Equipment.Spoon("to mix"),
    Equipment.Rolling_pin(),
    Equipment.Frying_pan("small, for many pitas use a large pan"),
    Equipment.Spatula("to flip"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Bread_flour(40, "1/3 cups"),
    Ingredients.Greek_yogurt("3 Tablespoons (2 if runnier)"),
    Ingredients.Olive_oil(5, "1 teaspoon, more for frying"),
    Ingredients.Bicarbonate_of_soda("1/4 teasposons"),
  ],

  optional_ingredients: [Ingredients.Salt()],

  prep: [
    "In a bowl mix all ingedients",
    "If needed keep adding flour and mix until the dough is like putty and doesn't stick",
  ],

  prep_time: 5,

  method: [
    "Lightly brush the pan with olive oil and warm on low heat",
    "Flour the table lightly",
    "Roll out the pita dough with the rolling pin until is 3-4mm thick and round",
    "Fry in the pan 3-4 minutes on each side until puffed and has a few brown spots",
  ],

  cook_time: 10,
};

export default Recipe;
