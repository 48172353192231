import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.entree,

  title: "Cauliflower",

  subtitle: "soup, oven-roasted",

  background: require("../../media/Roasted_cauliflower_soup.jpg"),

  details: ["Full of taste!", "Try it with shavings of a hard cheese (Cheddar, goat's, sheep's)"],

  equipment: [
    Equipment.Vertical_blender("IMPORTNANT"),
    Equipment.Pot("Medium-large"),
    Equipment.Lid(),
    Equipment.Spatula(),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Baking_tray("for all the cauliflower florets "),
    Equipment.Baking_paper("to cover the tray"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Cauliflower("1 large", "cut into small florets"),
    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Onion("1 large red", "chopped loosely"),
    Ingredients.Garlic("2 large cloves", "minced"),
    Ingredients.Butter(30),
    Ingredients.Lemon_juice("2 teaspoons", "from half a lemon"),
    Ingredients.Vegetable_broth("1 liter of", "from 2 concentrated cubes"),
    Ingredients.Nutmeg("1/4 teaspoon"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Creme_fraiche()],

  prep: [
    "Preheat the oven at 220°C / 200°C FAN",
    "Cut the cauliflower into small florets",
    "Chop the onion",
    "Peel and mince the garlic",
    "Make the vegetable broth",
  ],

  prep_time: 10,

  method: [
    "On the baking sheet, toss the cauliflower with 2 Tablespoons of the olive oil until lightly and evenly coated in oil",
    "Arrange the cauliflower in a single layer and sprinkle lightly with salt",
    "Bake until the cauliflower is tender and caramelized on the edges, 25 minutes",
    "In the soup pot heat 2 Tablespoons of olive oil on medium",
    "Sautee the onion with a pinch od salt for 5-7 minutes, stirring often",
    "Add the garlic and stir, cook for 30 seconds",
    "Stir in a pinch of pepper",
    "Add in the broth",
    "Reserve a couple of the prettiest roasted cauliflower florets for garnish",
    "Add in the rest of the cauliflower",
    "Bring to simmer then cover partially with lid and turn heat down",
    "Cook for 20 minutes stirring ocasionally",
    "Remove from heat and let the pot cool for 5 minutes",
    "Blend carefully with the vertical blender",
    "Add butter and lemon, blend more",
    "Taste and add more salt, blend",
    `(optional) Serve with a spoonful of ${Ingredients.Creme_fraiche().name}`,
  ],

  cook_time: 45,
};

export default Recipe;
