const Ingredients = {
  Asparagus: (count, details) => ({
    quantity: count,
    unit: count === 1 ? " stem of" : " stems of",
    name: "asparagus",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Avocado: (count, details) => ({
    quantity: count,
    unit: "piece",
    name: "avocado",
    details: `ripe`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Bacon: (count, details) => ({
    quantity: count,
    name: "bacon",
    unit: "",
    details: details || "",
    keto: true,
  }),

  Baking_powder: (count, details) => ({
    quantity: count,
    unit: "",
    name: "baking powder",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Baking_soda: (count, details) => ({
    quantity: count,
    unit: "",
    name: "baking soda",
    details: `(same as bicarbonate of soda) ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Banana: (count, details) => ({
    quantity: count,
    unit: "",
    name: `banana${count === 1 ? "" : "s"}`,
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Basil: (count, details) => ({
    quantity: count,
    unit: "",
    name: "basil",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Bay_leafs: (count, details) => ({
    quantity: count,
    unit: "",
    name: count === 1 ? " bay leaf" : " bay leafs",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Beef_stock: (quantity, details) => ({
    quantity: quantity,
    unit: quantity === 1 ? " cube" : " cubes",
    name: "beef stiock",
    details: details || "",
    keto: true,
  }),

  Bell_pepper: (count, details) => ({
    quantity: count,
    unit: "",
    name: `bell pepper${count === 1 ? "" : "s"}`,
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Bicarbonate_of_soda: (count, details) => ({
    quantity: count,
    unit: "",
    name: "bicarbonate of soda",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Bread_flour: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "stong bread flour",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Brussel_sprouts: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "brussel sprouts",
    details: `trimmed ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Butter: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "butter",
    details: `unsalted ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    keto: true,
  }),

  Cabbage: (count, details) => ({
    quantity: count,
    unit: "",
    name: "cabbage",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Capers: (count, details) => ({
    quantity: count,
    unit: "",
    name: "capers",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Caraway_seeds: (quantity, details) => ({
    quantity: quantity,
    name: "caraway seeds",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Cardamom: (quantity, details) => ({
    quantity: quantity,
    name: "cardamom",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Carrots: (count, details) => ({
    quantity: count,
    unit: "",
    name: count !== 1 ? "carrots" : "carrot",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Cauliflower: (count, details) => ({
    quantity: count,
    unit: "",
    name: "cauliflower",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Chicken_breast: (count, details) => ({
    quantity: count,
    unit: "",
    name: "chicken breast",
    details: `skinlesss, boneless ${details ? ` - ${details}` : ""}`,
    keto: true,
  }),

  Chicken_thighs: (count, details) => ({
    quantity: count,
    unit: "",
    name: "chicken thighs",
    details: details || "",
    keto: true,
  }),

  Chilli_powder: (quantity, details) => ({
    quantity: quantity,
    name: "chilli powder",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Chilli_flakes: (quantity, details) => ({
    quantity: quantity,
    name: "chilli flakes",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Chives: (count, details) => ({
    quantity: count,
    unit: "",
    name: "chives",
    details: `fresh ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Cinamon: (count, details) => ({
    quantity: count,
    unit: "",
    name: "cinamon",
    details: `ground ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Coriander: (count, details) => ({
    quantity: count,
    unit: "",
    name: "coriander",
    details: `fresh ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Corn: (count, details) => ({
    quantity: count,
    unit: "",
    name: "corn",
    details: `on the cob ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Cornichons: (count, details) => ({
    quantity: count,
    unit: "",
    name: `cornichon${count === 1 ? "" : "s"}`,
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Creme_fraiche: (count, details) => ({
    quantity: count,
    unit: "",
    name: "Créme Fraîche",
    details: details || "",
    vegetarian: true,
    keto: true,
  }),

  Cucumber: (count, details) => ({
    quantity: count,
    unit: "",
    name: "cucumber",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Cumin: (quantity, details) => ({
    quantity: quantity,
    name: "cumin",
    details: `ground ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Egg: (count, details) => ({
    quantity: count,
    name: count !== 1 ? "eggs" : "egg",
    details: `medium, raw ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    keto: true,
  }),

  Feta: (count, details) => ({
    quantity: count,
    unit: "",
    name: "feta cheese",
    details: details || "",
    vegetarian: true,
    keto: true,
  }),

  Flour: (count, details) => ({
    quantity: count,
    unit: "",
    name: "flour",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Garlic: (count, details) => ({
    quantity: count,
    unit: " of ",
    name: "garlic",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Garlic_powder: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "garlic powder",
    details: `or granules ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Greek_yogurt: (count, details) => ({
    quantity: count,
    unit: "",
    name: "greek yogurt",
    details: details || "",
    vegetarian: true,
  }),

  Green_beans: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "green beans",
    details: `ends trimmed ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Heavy_cream: (count, details) => ({
    quantity: count,
    unit: "",
    name: "Heavy cream",
    details: details || "",
    vegetarian: true,
    keto: true,
  }),

  Honey: (count, details) => ({
    quantity: count,
    unit: "",
    name: "honey",
    details: details || "",
    vegetarian: true,
  }),

  Horseradish: (count, details) => ({
    quantity: count,
    unit: "",
    name: "horseradish",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Hot_pepper: (count, details) => ({
    quantity: count,
    unit: "",
    name: "hot pepper",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Lamb_minced: (quantity, details) => ({
    quantity: quantity,
    name: "minced lamb",
    details: details || "",
    keto: true,
  }),

  Leg_of_lamb: (quantity, details) => ({
    quantity: quantity,
    name: "leg of lamb",
    details: details || "",
    keto: true,
  }),

  Lemon: (count, details) => ({
    quantity: count,
    unit: "",
    name: "lemon",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Lemon_juice: (count, details) => ({
    quantity: count,
    unit: "",
    name: "lemon juice",
    details: `freshly squeezed ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Lemon_zest: (count, details) => ({
    quantity: count,
    unit: "",
    name: "lemon zest",
    details: `freshly grated ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Lettuce: (count, details) => ({
    quantity: count,
    unit: "",
    name: "lettuce",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Lime_juice: (count, details) => ({
    quantity: count,
    unit: "",
    name: "lime juice",
    details: `freshly squeezed ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Mayonnaise: (count, details) => ({
    quantity: count,
    unit: "",
    name: "mayonnaise",
    details: details || "",
  }),

  Mozarella: (count, details) => ({
    quantity: count,
    unit: "",
    name: "mozarella cheese",
    details: details || "",
  }),

  Mushrooms: (count, details) => ({
    quantity: count,
    name: count === 1 ? " mushroom" : " mushrooms",
    unit: "",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Mustard: (count, details) => ({
    quantity: count,
    unit: "",
    name: "mustard",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Nutmeg: (quantity, details) => ({
    quantity: quantity,
    name: "nutmeg",
    details: `ground ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Nuts: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "nuts",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Olive_oil: (count, details) => ({
    quantity: count,
    unit: "ml",
    name: "olive oil",
    details: `extra virgin ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Olives: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "olives",
    details: `pitted ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Onion: (count, details) => ({
    quantity: count,
    unit: "",
    name: "onion",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Oregano: (count, details) => ({
    quantity: count,
    unit: "",
    name: "oregano",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Pancetta: (count, details) => ({
    quantity: count,
    unit: "",
    name: "pancetta",
    details: details || "",
    keto: true,
  }),

  Paprika: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "paprika",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Parmesan: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "parmesan",
    details: `freshly grated ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    keto: true,
  }),

  Parsley: (count, details) => ({
    quantity: count,
    unit: "",
    name: "parsley",
    details: `fresh ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Parsnips: (count, details) => ({
    quantity: count,
    unit: "g",
    name: "parsnips",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Pepper: (quantity, details) => ({
    quantity: quantity,
    name: "pepper",
    details: `freshly ground ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Plum_tomato: (count, details) => ({
    quantity: count,
    unit: "",
    name: count !== 1 ? "plum tomatoes" : "plum tomato",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Potatoes: (count, details) => ({
    quantity: count,
    name: "potatoes",
    unit: "",
    details: `${details ? ` - ${details}` : ""} washed`,
    vegetarian: true,
    vegan: true,
  }),

  Powdered_sugar: (count, details) => ({
    quantity: count,
    unit: "",
    name: "powdered sugar",
    details: details || "",
    vegetarian: true,
    vegan: true,
  }),

  Raisins: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "raisins",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Red_lentils: (quantity, details) => ({
    quantity: quantity,
    name: "red lentils",
    details: details || "",
    vegetarian: true,
    vegan: true,
  }),

  Rosemary: (quantity, details) => ({
    quantity: quantity,
    name: "rosemary",
    details: `fresh ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Salmon: (count, details) => ({
    quantity: count,
    unit: "",
    name: "salmon",
    details: details || "",
    keto: true,
  }),

  Salt: (quantity, details) => ({
    quantity: quantity,
    name: "salt",
    details: `freshly ground ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Sesame_seeds: (count, details) => ({
    quantity: count,
    unit: "",
    name: "sesame seeds",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Shallots: (count, details) => ({
    quantity: count,
    unit: "",
    name: `shallot${count === 1 ? "" : "s"}`,
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Soft_cheese: (count, details) => ({
    quantity: count,
    unit: "",
    name: "Soft cheese",
    details: details || "",
    vegetarian: true,
    keto: true,
  }),

  Soy_sauce: (count, details) => ({
    quantity: count,
    unit: "ml",
    name: "soy sauce",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Sparkling_mineral_warter: (count, details) => ({
    quantity: count,
    unit: "ml",
    name: "sparkling mineral warter",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Sugar: (count, details) => ({
    quantity: count,
    unit: "",
    name: "sugar",
    details: details || "",
    vegetarian: true,
    vegan: true,
  }),

  Sundried_tomatoes: (count, details) => ({
    quantity: count,
    unit: "",
    name: "sundried tomatoes",
    details: details || "",
    vegetarian: true,
    vegan: true,
  }),

  Thyme: (count, details) => ({
    quantity: count,
    unit: "",
    name: "thyme",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Tomato_paste: (count, details) => ({
    quantity: count,
    unit: "",
    name: "tomato paste",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Tomato_sauce: (count, details) => ({
    quantity: count,
    unit: "",
    name: "tomato sauce",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Tomatoes: (count, details) => ({
    quantity: count,
    name: count === 1 ? " tomato" : " tomatoes",
    unit: "",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Turkey_breast: (count, details) => ({
    quantity: count,
    unit: "",
    name: "turkey breast",
    details: `skinlesss, boneless ${details ? ` - ${details}` : ""}`,
    keto: true,
  }),

  Vanila: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "vanila extract",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Vegetable_broth: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "vegetable broth",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Vegetable_oil: (count, details) => ({
    quantity: count,
    unit: "ml",
    name: "vegatable oil",
    details: `sunflower / canola / rapeseed / etc. ${details ? ` - ${details}` : ""}`,
    vegetarian: true,
    vegan: true,
  }),

  Vinegar: (count, details) => ({
    quantity: count,
    unit: "ml",
    name: "vinegar",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Water: (quantity, details) => ({
    quantity: quantity,
    unit: "ml",
    name: "water",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Worcestershire_sauce: (count, details) => ({
    quantity: count,
    unit: "",
    name: "Worcestershire sauce",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Yeast: (quantity, details) => ({
    quantity: quantity,
    unit: "",
    name: "yeast",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),

  Yogurt: (count, details) => ({
    quantity: count,
    unit: "",
    name: "yogurt",
    details: details || "",
    vegetarian: true,
  }),

  Zucchini: (count, details) => ({
    quantity: count,
    unit: "",
    name: "Zucchini",
    details: details || "",
    vegetarian: true,
    vegan: true,
    keto: true,
  }),
};

export default Ingredients;
