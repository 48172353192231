const Item = (props, name, def) => {
  const Details = (props, def) => {
    if (props) return typeof props === "string" ? props : props.details || def;

    return def;
  };

  return {
    name: name,
    details: Details(props, def),
    ...props,
  };
};

const Equipment = {
  Frying_pan: (props) => Item(props, "frying pan", "non stick / teflon"),
  Pot: (props) => Item(props, "pot"),
  Wok: (props) => Item(props, "wok", "non stick / teflon"),

  // Lids:
  Glass_lid: (props) => Item(props, "glass lid", "transparent"),
  Lid: (props) => Item(props, "lid"),

  // Oven:
  Baking_dish: (props) => Item(props, "baking dish"),
  Baking_rack: (props) => Item(props, "baking rack"),
  Baking_tray: (props) => Item(props, "baking tray"),
  Loaf_tin: (props) => Item(props, "loaf tin"),
  Oven_gloves: (props) => Item(props, "oven gloves"),
  Cupcake_tray: (props) => Item(props, "cupcake tray"),
  Cupcake_papers: (props) => Item(props, "cupcake papers"),

  // Tools:
  Brush: (props) => Item(props, "brush", "silicon"),
  Cheese_grate: (props) => Item(props, "cheese grate"),
  Colander: (props) => Item(props, "colander"),
  Cutting_board: (props) => Item(props, "cutting board", "ideally plastic or glass"),
  Grate: (props) => Item(props, "grate"),
  Knife: (props) => Item(props, "knife", "for cutting"),
  Masher: (props) => Item(props, "masher"),
  Pestil_and_mortar: (props) => Item(props, "pestil and mortar"),
  Pizza_cutter: (props) => Item(props, "pizza cutter"),
  Rolling_pin: (props) => Item(props, "rolling pin"),
  Sieve: (props) => Item(props, "sieve"),
  Spatula: (props) => Item(props, "spatula", "scratch proof, plastic / teflon"),
  Strainer: (props) => Item(props, "strainer"),
  Tongues: (props) => Item(props, "tongues", "scratch proof, plastic / teflon"),
  Whisk: (props) => Item(props, "whisk"),

  // Cutlery:
  Spoon: (props) => Item(props, "spoon"),
  Fork: (props) => Item(props, "fork"),
  Butter_knife: (props) => Item(props, "butter knife", "for spreading"),

  // Consumables:
  Aluminium_foil: (props) => Item(props, "aluminium foil"),
  Baking_paper: (props) => Item(props, "baking sheet paper"),
  Cling_film: (props) => Item(props, "cling film"),

  // Crockery:
  Ceramic_bowl: (props) => Item(props, "ceramic bowl"),
  Mixing_bowl: (props) => Item(props, "mixing bowl"),
  Plate: (props) => Item(props, "plate"),
  Platter: (props) => Item(props, "platter"),
  Tupperware: (props) => Item(props, "plastic tupperware, with air tight lid"),

  // Electric:
  Mixer: (props) => Item(props, "electric mixer"),
  Vertical_blender: (props) => Item(props, "vertical blender"),
};

export default Equipment;
