import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "Yoghurt sauce",

  subtitle: "With garlic",

  background: require("../../media/Yoghurt_sauce.jpg"),

  details: [`Ideal for <a href='#/Shawarma'>Shawarma</a>.`],

  equipment: [Equipment.Pestil_and_mortar("or garlic crusher and a small bowl")],

  optional_equipment: [],

  ingredients: [
    Ingredients.Greek_yogurt("1 cup"),
    Ingredients.Garlic("2 cloves", "more or fewer if prefered"),
    Ingredients.Lemon_juice("1 teaspoon", "from 1/4 small lemon"),
    Ingredients.Salt("1/2 teaspoon"),
    Ingredients.Pepper("1/2 teaspoon"),
  ],

  optional_ingredients: [Ingredients.Cumin("1 teaspoon", "for more of a middle-eastern flavour")],

  prep: ["Trim the hard tip of the cloves", "Peel garlic cloves"],

  prep_time: 2,

  method: [
    "Put cloves in the mortar with the salt",
    "Grind all to a pulp",
    "Mix in the yogurt",
    "Squeeze lemon",
    "Grind pepper",
    "Mix well",
  ],

  cook_time: 3,
};

export default Recipe;
