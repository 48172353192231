import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Zucchini",

  subtitle: "oven-baked",

  background: require("../../media/baked_zucchini.jpg"),

  details: [
    "Fast to make, full of taste and very healthy!",
    `Try it with <a href='#/Turkey_escalopes_butter_lemon'>Turkey escalopes in butter and lemon sauce</a>.`,
  ],

  equipment: [
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Mixing_bowl("large"),
    Equipment.Baking_tray("lined with baking paper, if prefferd"),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Zucchini(4),
    Ingredients.Olive_oil(15, "1 Tablespoon"),
    Ingredients.Garlic("2 large cloves, minced"),
    Ingredients.Lemon_zest("from one lemon"),
    Ingredients.Thyme("1 teaspoon"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Hot_pepper("1 teraspoon dry flakes")],

  prep: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Wash the zucchinis",
    "Trim the ends then cut length-wise into quarters",
    "Cut into 8-12cm long sticks",
    "Mix the sticks into the bowl with remaining ingredients",
  ],

  prep_time: 10,

  method: [
    "Place the sticks into the baking tray with the interior side up, skin down",
    "Bake for 30-40 minutes, until the tips brown up a bit",
  ],

  cook_time: 35,
};

export default Recipe;
