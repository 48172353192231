import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Fajitas",

  subtitle: "With chicken, turkey or beef",

  background: require("../../media/fajitas.jpg"),

  details: [
    "Colorful and delicious!",
    "With any onions, any peppers and any meat.",
    "Make it without meat as a side.",
    `Try it with 
    <a href='#/Guacamole'>Guacamole</a>
     and 
    <a href='#/Pico_de_gallo'>Pico de Gallo</a>.`,
    "Serve with SOUR CREAM.",
  ],

  equipment: [
    Equipment.Mixing_bowl(),
    Equipment.Plate("for chicken"),
    Equipment.Plate("large, for vegetables"),
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Frying_pan("large"),
    Equipment.Spatula(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Chicken_breast("400g", "or turkey / beef"),
    Ingredients.Onion(1, "any type, thinly sliced"),
    Ingredients.Bell_pepper(3, "mixed colors, thinly sliced"),
    Ingredients.Lime_juice("", "from 1/2 lime"),
    Ingredients.Olive_oil(15, "1 Tablespoon"),

    Ingredients.Chilli_powder("1/2 Tablespoon"),
    Ingredients.Cumin("1/2 Tablespoon"),
    Ingredients.Garlic_powder("1 teaspoon"),
    Ingredients.Paprika("1/2 teaspoon"),
    Ingredients.Oregano("1/2 teaspoon"),
    Ingredients.Salt("1/2 teaspoon"),
    Ingredients.Pepper("1/4 teaspoon"),
  ],

  optional_ingredients: [],

  prep: [
    "In a bowl mix all the dry condiments",
    "Generously sprinkle the fajita seasoning on both sides of the meat",
    "Use your fingers to press it into the meat",
    "Slice oninon",
    "Slice peppers",
  ],

  prep_time: 10,

  method: [
    "Heat the oil in the pan over medium heat",
    "Sear the meat 7 minutes on each side",
    "Let the cooked meat rest",
    "Add peppers and onion to the same pan",
    "Cook for 5 minutes, stirring frequently",
    "Between stirring slice the meat into strips",
    "Add the meat strips to the pan",
    "Pour the lime juice on top and mix",
    "Remove from heat and serve immediately",
  ],

  cook_time: 20,
};

export default Recipe;
