import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.bread,

  title: "Soda bread",

  subtitle: "No kneading or raising",

  background: require("../../media/soda_bread.jpg"),

  details: [
    "Quick and easy, without any kneading or raising time required!",
    "Using ingredinets that last in the fridge/cupboard.",
  ],

  equipment: [
    Equipment.Mixing_bowl("large"),
    Equipment.Spatula("soft, to mix"),
    Equipment.Loaf_tin("small, for big loaf tins double the quantities"),
    Equipment.Knife("very sharp, (or a razor) for bread top"),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [Equipment.Baking_rack()],

  ingredients: [
    Ingredients.Bread_flour(500),
    Ingredients.Salt("2 teaspoons"),
    Ingredients.Baking_powder("2 teasposons", "or 1/2 teasposon BICARBONATE OF SODA"),
    Ingredients.Sparkling_mineral_warter(400, "or SODA"),
    Ingredients.Lemon_juice("30ml", "from 1 lemon, or 15ml VINEGAR"),
    Ingredients.Sugar("1 teasposon", "or HONEY"),
    Ingredients.Olive_oil(5, "to line the loaf tin, or use BUTTER"),
  ],

  optional_ingredients: [Ingredients.Olives("100", "sliced or diced")],

  prep: [
    "Preheat the oven at 200°C / 180°C FAN",
    "In a large bowl mix dry ingedients (flour, salt and bicarbonate of soda, also sugar if used instead of honey)",
    "Add wet ingredients (sparkling mineral water, lemon juice/vinegar, honey if used",
    "(optional) Mix in olives",
  ],

  prep_time: 10,

  method: [
    "Coat loaf tin with oil/butter",
    "Pour the mix in the loaf tin - shouldn't fill more than 3/4 of the tin",
    "(optional) Sprinkle flour on top",
    "make a cut the whole length of the loaf on top",
    "Bake for 1h 15m",
    "Bread loaf should come out of the tin sounding hollow when tapped on the bottom",
    "Leave loaf to rest on a baking rack for at least 1h 30m",
    "Remember that bread than always be baked more if it's still wet inside",
  ],

  cook_time: 80,
};

export default Recipe;
