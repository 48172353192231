import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Parsnip chips",

  subtitle: "baked in the oven",

  background: require("../../media/parsnips.jpg"),

  details: ["As a side or for snacking, with any prefered sauce."],

  equipment: [
    Equipment.Cutting_board(),
    Equipment.Knife(),
    Equipment.Baking_tray("big enouth so the chips don't touch each other"),
    Equipment.Baking_paper("to line the tray"),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Parsnips(400, "1 bag"),
    Ingredients.Olive_oil(15, "to drizzle"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [],

  prep: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Wash the parsnips and cut into chips, 5 to 8mm thick",
    "Do now peel the skin",
    "Line baking tray with baking paper",
  ],

  prep_time: 5,

  method: [
    "Place parsnip chips in the tray on the paper",
    "Drizzle a bit of olive oil on all chips",
    "Grind salt and pepper over all chips",
    "Bake in the oven for 35 minutes",
    "Tips should brown a bit, chips should be crunchy on the outside",
  ],

  cook_time: 35,
};

export default Recipe;
