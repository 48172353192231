import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.entree,

  title: "Parsnip cream",

  subtitle: "soup, oven-roasted",

  background: require("../../media/Parsnip_soup.jpg"),

  details: [
    "Warm and hearty!",
    `To roast the parsnips follow this <a href='#/Parsnip_chips'>oven baked parsnips</a> recipe.`,
  ],

  equipment: [
    Equipment.Vertical_blender("IMPORTNANT"),
    Equipment.Pot("Medium-large"),
    Equipment.Lid(),
    Equipment.Spatula(),
    Equipment.Cutting_board(),
    Equipment.Knife(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Onion("1 large white", "chopped loosely"),
    Ingredients.Carrots(1, "peeled, chopped loosely"),
    Ingredients.Garlic("2 large cloves", "minced"),
    Ingredients.Tomato_paste("1 Tablespoon"),
    Ingredients.Paprika("1 teaspoon"),
    Ingredients.Red_lentils("1 teaspoon"),
    Ingredients.Vegetable_broth("1 liter of", "from 2 concentrated cubes"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Butter(30), Ingredients.Greek_yogurt("", "2 Tablespoons per serving")],

  prep: [
    `Make <a href='#/Parsnip_chips'>oven baked parsnips</a>.`,
    "(optional) Bake some parsnip thin slices for garnish",
    "Chop the onion",
    "Peel and chop the carrot",
    "Peel and mince the garlic",
    "Make the vegetable broth",
  ],

  prep_time: 10,

  method: [
    "In the soup pot heat the olive oil on medium",
    "Sautee the onion for 5 minutes, stirring often",
    "Add the garlic, tomato paste, paprika, a pinch of salt and pepper",
    "Stir, then cook for 30 seconds",
    "Add broth, and carrot",
    "Bring to simmer then cover partially with lid and turn heat down",
    "Boil for 30 minutes",
    "Remove from heat and blend carefully with the vertical blender",
    "Add butter, blend more",
    "Taste and add more salt, blend",
    `(optional) Serve with Greek yogurs and roasted parsnip slices`,
  ],

  cook_time: 45,
};

export default Recipe;
