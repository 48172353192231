import React, { useEffect } from "react";
import { recipeList } from "./db/recipes";
import { recipeTypes } from "./db/recipeTypes";
import RecipeCards from "./components/RecipeCards";

function Menu() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main>
        <h1 className="home-title">Radu's Recipes</h1>
        <br />
        <hr />

        {Object.keys(recipeTypes).map((t, index) => (
          <section key={index}>
            <h2 className="home-type">{`${recipeTypes[t]}s`.toUpperCase()}</h2>
            <br />
            <RecipeCards recipeList={recipeList} type={recipeTypes[t]} />
          </section>
        ))}
      </main>
      <footer>*Recipe badges don't account for optional ingredients</footer>
    </>
  );
}

export default Menu;
