import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Pizza",

  subtitle: "In the oven",

  background: require("../../media/pizza.jpg"),

  details: [
    "Easy to make with no waiting time!",
    "This recipe focuses mainly on the pizza base, toppings can vary...",
    "My favourite toppings are bacon, mushrooms, black olives, bell pepper and red onion",
    "Sauce recipe: <a href='#/Pizza_sauce'>Pizza sauce</a>",
  ],

  equipment: [
    Equipment.Mixing_bowl(),
    Equipment.Spatula(),
    Equipment.Rolling_pin(),
    Equipment.Baking_tray(),
    Equipment.Platter("large, for cutting and serving"),
  ],

  optional_equipment: [Equipment.Brush(), Equipment.Pizza_cutter(), Equipment.Cutting_board(), Equipment.Knife()],

  ingredients: [
    Ingredients.Flour("400g", "ideally bread flour, plain flour works as well"),
    Ingredients.Flour("more", "for rolling"),
    Ingredients.Salt("1 teaspoon"),
    Ingredients.Sugar("1 teaspoon"),
    Ingredients.Yeast("7g", "1 satchet"),
    Ingredients.Water(225, "warm, but not hot"),
    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Tomato_sauce("4 Tablespoons", "pizza sauce (see recipe above)"),
    Ingredients.Mozarella("200g", "matured, grated"),
  ],

  optional_ingredients: [
    Ingredients.Bacon("4 slices", "cooked, coarsely chopped"),
    Ingredients.Mushrooms(2, "medium-large, chopped"),
    Ingredients.Olives(12, "black, sliced"),
    Ingredients.Onion("1/2", "red, diced"),
    Ingredients.Bell_pepper("1/4", "red, diced"),
  ],

  prep: [
    "Preheat the oven at 240°C / 220°C FAN",
    "In a bowl mix flour, salt, sugar and yeast",
    "Add olive oil and water",
    "Mix to bring everything together",
    "Tip onto a floured surface and knead for 1 minute, until smooth",
    "Make a large ball and let the dough rest under the upturned bowl",
    "( Prep toppings... )",
    "Cut the ball in half - each makes a ⌀35cm pizza",
    "If not cooked immediately store in frige in an air-tight container or bag",
  ],

  prep_time: 20,

  method: [
    "Roll the dough ball onto a floured surface to about ⌀30cm",
    "Roll the edge to make it thicker, base should be less than 5mm thick, crust 1cm",
    "Move the dough to the baking tray",
    "Stretch and press the pizza dough with your fingertips until it's about ⌀35cm",
    "Add topings, starting with <a href='#/Pizza_sauce'>Pizza sauce</a>, then mozzarela",
    "Add all other topings...",
    "Bake for 10 minutes, until the base is golden and crisp",
  ],

  cook_time: 20,
};

export default Recipe;
