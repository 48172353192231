import Equipment from "../equipment";
import Ingredients from "../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Turkey escalopes",

  subtitle: "butter and lemon sauce",

  background: require("../../media/turkey_escalopes_butter_lemon.jpg"),

  details: [
    "Fantasting taste!",
    "Bringing amazing flavour to turkey breast.",
    `Goes great with <a href='#/Baked_zucchini'>Baked zucchini</a>.`,
  ],

  equipment: [Equipment.Frying_pan(), Equipment.Platter(), Equipment.Spatula()],

  optional_equipment: [],

  ingredients: [
    Ingredients.Turkey_breast("4 escalopes"),
    Ingredients.Flour("50g"),
    Ingredients.Olive_oil(15, "1 Tablespoon"),
    Ingredients.Butter(50),
    Ingredients.Lemon("1/2"),
  ],

  optional_ingredients: [Ingredients.Salt("1/2 teaspoon")],

  prep: ["Flour the meat on both sides"],

  prep_time: 3,

  method: [
    "In the pan melt half of the butter in the olive oil over medium heat",
    "Add in the escalopes and fry on each side for about 3-4 minutes until golden",
    "Squeeze the half lemon on top of the meat",
    "Move the escalopes to the platter",
    "In the same pan melt the rest of the butter and (optionally) add salt",
    "Pour the sauce on top of your turkey escalopes",
  ],

  cook_time: 12,
};

export default Recipe;
