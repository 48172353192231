import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.sauce,

  title: "gucacamole",

  subtitle: "from one avocado",

  background: require("../../media/guacamole.jpg"),

  details: [
    "Fast and easy to make!",
    "Easy to portion by number of avocados and plum toamatoes used.",
    `Try it with 
    <a href='#/Fajitas'>Fajitas</a>
    and 
    <a href='#/Pico_de_gallo'>Pico de Gallo</a>.`,
  ],

  equipment: [Equipment.Cutting_board(), Equipment.Knife(), Equipment.Ceramic_bowl("With any cover"), Equipment.Fork()],

  optional_equipment: [Equipment.Sieve("for lemon juice")],

  ingredients: [
    Ingredients.Avocado(),
    Ingredients.Plum_tomato(1, "diced"),
    Ingredients.Onion("2 Tablespoons", "red, white or green, diced"),
    Ingredients.Lemon_juice("1 teaspoon", "from half a small lemon or a LIME"),
    Ingredients.Salt(),
  ],

  optional_ingredients: [Ingredients.Hot_pepper("As preffered", "fresh/pickled chopped or flakes")],

  prep: [
    "Dice the tomato, oninon and if preffered hot pepper",
    "Squeeze the juice from the lemon without seeds, some pulp is fine",
  ],

  prep_time: 5,

  method: [
    "Cut avocado in half and remove seed",
    "Scoop contents in the bowl",
    "Mash avocado pulp with the fork",
    "Mix with lemon juice and salt",
    "Mix in the rest of the ingredients",
    "Keep bowl covered in the fridge",
  ],

  cook_time: 5,
};

export default Recipe;
