import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Greek salad",

  subtitle: "side or main",

  background: require("../../media/greek_salad.jpg"),

  details: [
    "Classic Greek side salad, or make it larger as a main.",
    "This recipe is for 4 portions.",
    `Try it with 
    <a href='#/Greek_lamb_loaves'>Greek lamb loaves</a>.`,
  ],

  equipment: [Equipment.Cutting_board(), Equipment.Knife(), Equipment.Mixing_bowl()],

  optional_equipment: [Equipment.Ceramic_bowl("One for each portion, to serve")],

  ingredients: [
    Ingredients.Olive_oil(45, "3 Tablespoons"),
    Ingredients.Vinegar(30, "2 Tablespoons"),
    Ingredients.Garlic("2 cloves", "minced"),
    Ingredients.Oregano("1/2 teaspoon dry"),
    Ingredients.Mustard("1/2 teaspoon Dijon"),
    Ingredients.Salt("1/2 teaspoon"),
    Ingredients.Pepper("1/2 teaspoon"),

    Ingredients.Cucumber(1, "cut lengthwise, seeded, and sliced 5mm thick"),
    Ingredients.Bell_pepper(1, "chopped into 2cm pieces"),
    Ingredients.Tomatoes("4 medium", "chopped in 2cm chunks"),
    Ingredients.Onion(1, "thinly sliced"),
    Ingredients.Olives(100, "around 60 olives"),
    Ingredients.Feta("200g", "cut into one slice for each portion"),
  ],

  optional_ingredients: [
    Ingredients.Oregano("1 teaspoon dry", "more, to sprinkle on top of the cheese"),
    Ingredients.Lettuce("1/2", "chopped into 2cm pieces"),
  ],

  prep: [
    "Make the dressing by combining olive oil, vinegar, garlic, oregano, mustard, salt and pepper",
    "Chop the tomatoes, oninon, pepper and if preffered lettuce",
  ],

  prep_time: 15,

  method: [
    "Mix all ingredients in the bowl",
    "Mix in the dressing",
    "Divide in individual bowls",
    "Place Feta slice on top",
    "(optional) Sprinkle oregano and drizzle olive oil on top",
  ],

  cook_time: 5,
};

export default Recipe;
