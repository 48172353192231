import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.main,

  title: "Salmon filets",

  subtitle: "baked in the oven",

  background: require("../../media/salmon.jpg"),

  details: [
    "Perfecttly cooked, never dry, no oil!",
    `Great with <a href='#/Asparagus'>Asparagus</a>.`,
    `Try it with home-made <a href='#/Tartare_sauce'>Tartare sauce</a>.`,
  ],

  equipment: [
    Equipment.Baking_tray("for all the sprouts cut in half"),
    Equipment.Baking_paper("to cover the tray"),
    Equipment.Spatula(),
    Equipment.Oven_gloves(),
  ],

  optional_equipment: [],

  ingredients: [Ingredients.Salmon(false, "filets"), Ingredients.Salt(), Ingredients.Pepper()],

  optional_ingredients: [],

  prep: [
    "Preheat the oven at 200°C / 180°C FAN",
    "Line baking tray with baking paper",
    "Apply salt and pepper to the skin of the filets",
  ],

  prep_time: 2,

  method: [
    "Place in the tray on the paper, skin side down",
    "Apply salt and pepper to the top of the filets",
    "Bake in the oven for 18 minutes",
  ],

  cook_time: 18,
};

export default Recipe;
