import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.side,

  title: "Asparagus",

  subtitle: "pan-fried",

  background: require("../../media/asparagus.jpg"),

  details: ["Tasty and green!", `Try it with <a href='#/Baked_salmon_filets'>Baked salmon filets</a>.`],

  equipment: [Equipment.Frying_pan("very large"), Equipment.Tongues(), Equipment.Platter("for serving")],

  optional_equipment: [],

  ingredients: [
    Ingredients.Asparagus(15, "1 bunch"),
    Ingredients.Olive_oil(5, "1 teaspoon"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Butter(10)],

  prep: [
    "Heat the olive oil over medium heat in the pan but don't let it burn",
    "Wash the asparagus stems",
    "Trim the bottoms only if they don't fit in the pan",
  ],

  prep_time: 2,

  method: [
    "Throw the stems in the pan to fry",
    "Keep tossing them so they get cooked on all sides",
    "Grind salt and pepper",
    "The stems should not get soft",
    "Once the stems become bright green remove from heat",
    "Place asparagus on the platter",
    "(optional) Melt butter over the tips when served",
  ],

  cook_time: 5,
};

export default Recipe;
